import React, { useState } from 'react';
import { TextField, Button, Typography, Box, Paper } from '@mui/material';
import { sendPasswordResetEmail } from './firebaseConfig';

function ForgotPassword({ onBack }) {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(email);
      setMessage('Password reset email sent. Check your inbox.');
      setError('');
    } catch (error) {
      setError('Failed to send password reset email. Please try again.');
      setMessage('');
    }
  };

  return (
    <Box mb={4}>
      <Paper elevation={3} sx={{ padding: 4, maxWidth: 400, margin: 'auto' }}>
        <Typography variant="h5" gutterBottom align="center">
          Reset Password
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email Address"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <Button 
            variant="contained" 
            color="primary" 
            type="submit"
            fullWidth
            sx={{ mt: 2, mb: 2 }}
          >
            Send Reset Email
          </Button>
        </form>
        {message && <Typography color="primary">{message}</Typography>}
        {error && <Typography color="error">{error}</Typography>}
        <Button 
          variant="text" 
          onClick={onBack}
          fullWidth
          sx={{ mt: 2 }}
        >
          Back to Sign In
        </Button>
      </Paper>
    </Box>
  );
}

export default ForgotPassword;