import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { 
  getAuth, 
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged, 
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail as firebaseSendPasswordResetEmail
} from "firebase/auth";
import { getFirestore } from "firebase/firestore"; // Import Firestore

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCfxEtgblAqsHgNhvmzti15ZwFuss8TUhY",
  authDomain: "webmonitor-ee44f.firebaseapp.com",
  projectId: "webmonitor-ee44f",
  storageBucket: "webmonitor-ee44f.appspot.com",
  messagingSenderId: "358747983963",
  appId: "1:358747983963:web:bc429487e695f62181af7e",
  measurementId: "G-TJQ09EZ49Z"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app); // Initialize Firebase Auth
const firestore = getFirestore(app); // Initialize Firestore
const googleProvider = new GoogleAuthProvider();

export { 
  app, 
  analytics, 
  auth, 
  firestore, // Export Firestore
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged, 
  signOut,
  googleProvider,
  signInWithPopup
};

export const sendPasswordResetEmail = (email) => {
  return firebaseSendPasswordResetEmail(auth, email);
};