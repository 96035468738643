import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { Container, Typography, Paper, Box, Snackbar, Alert, Card, CardContent, Grid, TextField, Button, Divider, Link, Accordion, AccordionSummary, AccordionDetails, useMediaQuery, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GoogleButton from 'react-google-button';
import JobForm from './JobForm';
import JobList from './JobList';
import StripePricing from './StripePricing';
import ForgotPassword from './ForgotPassword';
import { Eye } from 'lucide-react';
import {
  DollarSign,
  Briefcase,
  Package,
  Newspaper,
  Calendar,
  Rocket,
  FileText,
  Bell,
} from 'lucide-react';
import { auth, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, googleProvider, signInWithPopup, sendPasswordResetEmail } from './firebaseConfig';
import { getIdToken } from "firebase/auth";
import { AuthContext, AuthProvider } from './AuthContext';
import useWebSocket from './hooks/useWebSocket';

const API_URL = process.env.REACT_APP_API_URL || '/api';
const WS_URL = process.env.REACT_APP_WS_URL || `wss://${window.location.host}/ws`;

const useCases = [
  { icon: DollarSign, title: 'Price Drops', description: 'Alert when price drops below $X' },
  { icon: Briefcase, title: 'Job Listings', description: 'Notify when "Job Title" is listed' },
  { icon: Package, title: 'Product Availability', description: 'Get alerts when items are restocked' },
  { icon: Newspaper, title: 'New Articles', description: 'Notify if you see negative news about X' },
  { icon: Calendar, title: 'Event Changes', description: 'Track updates to event details' },
  { icon: Rocket, title: 'Competitor Updates', description: 'Monitor new product launches' },
  { icon: FileText, title: 'Policy Changes', description: 'Watch for updates to terms or policies' },
  { icon: Bell, title: 'Custom Alerts', description: 'Set up alerts for any change' },
];

function UseCases() {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h5" component="h2">Example Prompts / Use Cases</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {useCases.map((useCase, index) => {
            const IconComponent = useCase.icon;
            return (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <IconComponent style={{ marginRight: '8px', marginTop: '4px' }} />
                  <div>
                    <Typography variant="subtitle1" component="h3">
                      {useCase.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {useCase.description}
                    </Typography>
                  </div>
                </div>
              </Grid>
            );
          })}
        </Grid>
        <Typography variant="caption" display="block" gutterBottom sx={{ mt: 2 }}>
          * Limited to monitoring initial pages and cannot handle websites requiring login, deep scrolling, or navigation.
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}

function App() {
  const { currentUser } = useContext(AuthContext);
  const [jobs, setJobs] = useState([]);
  const [newJob, setNewJob] = useState({
    url: '',
    info: '',
    frequency: '1440',
    status: 'Active',
  });
  const [errors, setErrors] = useState({});
  const [userId, setUserId] = useState(null);
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'info',
  });
  const [subscriptionLevel, setSubscriptionLevel] = useState('Unregistered');
  const [activeJobsCount, setActiveJobsCount] = useState(0);
  const [userEmail, setUserEmail] = useState('');
  const [password, setPassword] = useState('');
  const wsRef = useRef(null);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Define limits for subscription levels
  const limits = {
    'Unregistered': { maxJobs: 1 },
    'Basic': { maxJobs: 3 },
    'Pro': { maxJobs: 5 },
  };

  const fetchJobs = useCallback(async () => {
    if (!currentUser) {
      console.log('No authenticated user, skipping job fetch');
      return;
    }
    try {
      const token = await currentUser.getIdToken(true);  // Force token refresh
      console.log('Fetching jobs with token:', token);

      const jobsResponse = await fetch(`${API_URL}/data`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      
      console.log('Jobs response status:', jobsResponse.status);
      
      if (!jobsResponse.ok) {
        const errorText = await jobsResponse.text();
        console.error('Jobs response error:', errorText);
        throw new Error(`Failed to fetch jobs: ${jobsResponse.status} ${jobsResponse.statusText}`);
      }

      const jobsData = await jobsResponse.json();
      console.log('Fetched jobs:', jobsData);
      const nonDeletedJobs = jobsData.filter(job => job.status !== 'Deleted');
      setJobs(nonDeletedJobs);
      setActiveJobsCount(nonDeletedJobs.length);

      // Fetch user data separately
      const userResponse = await fetch(`${API_URL}/users/${currentUser.uid}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      console.log('User response status:', userResponse.status);
      
      if (!userResponse.ok) {
        const errorText = await userResponse.text();
        console.error('User response error:', errorText);
        throw new Error(`Failed to fetch user data: ${userResponse.status} ${userResponse.statusText}`);
      }
      const userData = await userResponse.json();
      console.log('Fetched user data:', userData);
      setSubscriptionLevel(userData.subscriptionStatus || 'Unregistered');
      setUserEmail(userData.email || '');

    } catch (error) {
      console.error('Error when fetching data:', error);
      setNotification({
        open: true,
        message: 'Failed to fetch data. Please try again later.',
        severity: 'error',
      });
      setJobs([]);
      setSubscriptionLevel('Unregistered');
      setActiveJobsCount(0);
    }
  }, [API_URL, currentUser]);

  useEffect(() => {
    if (currentUser) {
      setUserId(currentUser.uid);
      setUserEmail(currentUser.email);
      fetchJobs();
    } else {
      setUserId(null);
      setUserEmail('');
      setJobs([]);
    }
  }, [currentUser, fetchJobs]);

  const handleEmailPasswordAuth = async (e) => {
    e.preventDefault();
    if (!userEmail || !password) {
      setErrors({ auth: 'Please enter both email and password.' });
      return;
    }

    console.log('Attempting authentication with:', { email: userEmail, passwordLength: password.length });

    try {
      // Attempt to create a new account
      const userCredential = await createUserWithEmailAndPassword(auth, userEmail, password);
      console.log('New account created:', userCredential);
      setNotification({
        open: true,
        message: 'Account created and signed in successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Detailed error:', error);
      if (error.code === 'auth/email-already-in-use') {
        // If the email is already in use, try to sign in
        try {
          const signInCredential = await signInWithEmailAndPassword(auth, userEmail, password);
          console.log('Signed in with existing account:', signInCredential);
          setNotification({
            open: true,
            message: 'Signed in successfully!',
            severity: 'success',
          });
        } catch (signInError) {
          console.error('Error signing in:', signInError);
          setNotification({
            open: true,
            message: `Failed to sign in: ${signInError.message}`,
            severity: 'error',
          });
        }
      } else {
        console.error('Error creating account:', error);
        setNotification({
          open: true,
          message: `Failed to create account: ${error.message}`,
          severity: 'error',
        });
      }
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setNotification({
        open: true,
        message: 'Signed out successfully.',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error signing out:', error);
      setNotification({
        open: true,
        message: 'Failed to sign out.',
        severity: 'error',
      });
    }
  };

  const deleteJob = async (id) => {
    try {
      const jobToDelete = jobs.find(job => job.id === id);
      if (jobToDelete) {
        const updatedJob = { ...jobToDelete, status: 'Deleted' };
        const response = await fetch(`${API_URL}/data/${id}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updatedJob),
        });
        if (!response.ok) {
          throw new Error(`Failed to delete job: ${response.status} ${response.statusText}`);
        }
        setJobs((prevJobs) => prevJobs.filter(job => job.id !== id));
        setNotification({
          open: true,
          message: 'Job deleted successfully!',
          severity: 'success',
        });
      }
    } catch (error) {
      console.error('Error deleting job:', error);
      setNotification({
        open: true,
        message: 'Failed to delete job.',
        severity: 'error',
      });
    }
  };

  const saveJob = async (job) => {
    try {
      const token = await currentUser.getIdToken();
      const response = await fetch(`${API_URL}/data`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(job),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `Failed to save job: ${response.status}`);
      }
      const result = await response.json();
      setJobs((prevJobs) => [...prevJobs, result.job]);
      setActiveJobsCount((prevCount) => prevCount + 1);
      setNotification({
        open: true,
        message: 'Job added successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Network error when saving job:', error);
      setNotification({
        open: true,
        message: `Failed to add job: ${error.message}`,
        severity: 'error',
      });
    }
  };

  const toggleJobStatus = async (id) => {
    const jobToUpdate = jobs.find((job) => job.id === id);
    if (jobToUpdate) {
      const updatedJob = {
        ...jobToUpdate,
        status: jobToUpdate.status === 'Active' ? 'Stopped' : 'Active',
      };
      try {
        const response = await fetch(`${API_URL}/data/${id}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updatedJob),
        });
        if (!response.ok) {
          throw new Error(`Failed to update job: ${response.status} ${response.statusText}`);
        }
        const result = await response.json();
        setJobs((prevJobs) => prevJobs.map((job) => (job.id === id ? result.job : job)));
        setNotification({
          open: true,
          message: `Job ${updatedJob.status === 'Active' ? 'started' : 'stopped'} successfully!`,
          severity: 'success',
        });
      } catch (error) {
        console.error('Error updating job status:', error);
        setNotification({
          open: true,
          message: 'Failed to toggle job status.',
          severity: 'error',
        });
      }
    }
  };

  const handleAddJob = async () => {
    const validationErrors = {};
    let processedUrl = newJob.url.trim();
    if (!processedUrl.startsWith('http://') && !processedUrl.startsWith('https://')) {
      processedUrl = 'https://' + processedUrl;
    }

    if (!isValidUrl(processedUrl)) {
      validationErrors.url = 'Please enter a valid URL (e.g., https://www.example.com)';
    }
    if (newJob.info.trim() === '') {
      validationErrors.info = 'Description cannot be empty';
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    if (!currentUser) {
      setNotification({
        open: true,
        message: 'You must be signed in to add a job.',
        severity: 'error',
      });
      return;
    }

    const jobToSave = {
      url: processedUrl,
      info: newJob.info,
      frequency: newJob.frequency,
      status: 'Active',
    };

    try {
      console.log('Attempting to save job:', jobToSave);
      const token = await currentUser.getIdToken();
      console.log('Got token for job creation:', token);
      const response = await fetch(`${API_URL}/data`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(jobToSave),
      });
      console.log('Job creation response status:', response.status);
      
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Job creation error:', errorData);
        throw new Error(errorData.error || `Failed to save job: ${response.status}`);
      }
      
      const result = await response.json();
      console.log('Job creation successful:', result);
      setJobs((prevJobs) => [...prevJobs, result.job]);
      setActiveJobsCount((prevCount) => prevCount + 1);
      setNewJob({ url: '', info: '', frequency: '1440', status: 'Active' });
      setErrors({});
      setNotification({
        open: true,
        message: 'Job added successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error saving job:', error);
      setNotification({
        open: true,
        message: `Failed to add job: ${error.message}`,
        severity: 'error',
      });
    }
  };

  const updateUserEmail = async (userId, email) => {
    try {
      const response = await fetch(`${API_URL}/users/${userId}/email`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });
      if (!response.ok) {
        throw new Error('Failed to update user email');
      }
    } catch (error) {
      console.error('Error updating user email:', error);
      throw error;
    }
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotification((prev) => ({ ...prev, open: false }));
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const isValidEmail = (string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(string);
  };

  const handleSubscriptionSuccess = () => {
    fetchJobs();
    setNotification({
      open: true,
      message: 'Subscription successful! Thank you for subscribing.',
      severity: 'success',
    });
  };

  const canAddMoreJobs = useCallback(() => {
    const jobCount = jobs.filter(job => job.status !== 'Deleted').length;
    switch (subscriptionLevel) {
      case 'Basic':
        return jobCount < 3;
      case 'Pro':
        return jobCount < 5;
      case 'Unregistered':
      default:
        return jobCount < 1;
    }
  }, [subscriptionLevel, jobs]);

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      setNotification({
        open: true,
        message: 'Signed in successfully with Google!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error signing in with Google:', error);
      setNotification({
        open: true,
        message: 'Failed to sign in with Google. Please try again.',
        severity: 'error',
      });
    }
  };

  const handleNotification = (notificationData) => {
    setNotification(notificationData);
  };

  const handleWebSocketMessage = (data) => {
    if (data.type === 'jobUpdate') {
      setJobs((prevJobs) =>
        prevJobs.map((job) => (job.id === data.job.id ? data.job : job))
      );
    } else if (data.type === 'jobDelete') {
      setJobs((prevJobs) => prevJobs.filter((job) => job.id !== data.job.id));
    }
  };

  useWebSocket(WS_URL, jobs, setJobs);

  return (
    <Container maxWidth="md">
      <Box 
        component="header" 
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: theme.spacing(2, 0),
          marginBottom: theme.spacing(4),
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Eye 
          style={{ 
            color: theme.palette.primary.main, 
            width: '32px', 
            height: '32px', 
            marginRight: theme.spacing(2) 
          }} 
        />
        <Box>
          <Typography 
            variant="h4" 
            component="h1" 
            sx={{ 
              fontWeight: 'bold', 
              margin: 0,
              [theme.breakpoints.down('sm')]: {
                fontSize: '1.75rem',
              },
            }}
          >
            webmonitor.fyi
          </Typography>
          <Typography 
            variant="subtitle1" 
            color="textSecondary"
            sx={{
              [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
              },
            }}
          >
            AI-Powered Website Monitor
          </Typography>
        </Box>
      </Box>

      <Box mb={4}>
        <Paper elevation={0} sx={{ padding: 2 }}>
          <Typography variant="body1" align="center">
            Enter the URL and describe in plain words what you want to monitor. Our AI will alert you based on your criteria.
          </Typography>
        </Paper>
      </Box>

      <Box mb={4}>
        <UseCases />
      </Box>

      {!currentUser && !showForgotPassword && (
        <Box mb={4}>
          <Paper elevation={3} sx={{ padding: 4, maxWidth: 400, margin: 'auto' }}>
            <Typography variant="h5" gutterBottom align="center">
              Sign In / Sign Up
            </Typography>
            <form onSubmit={handleEmailPasswordAuth}>
              <TextField
                label="Email Address"
                type="email"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                error={Boolean(errors.email)}
                helperText={errors.email}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={Boolean(errors.password)}
                helperText={errors.password}
                fullWidth
                margin="normal"
                required
              />
              <Button 
                variant="contained" 
                color="primary" 
                type="submit"
                fullWidth
                sx={{ mt: 2, mb: 2 }}
              >
                Sign In / Sign Up
              </Button>
            </form>
            <Link
              component="button"
              variant="body2"
              onClick={() => setShowForgotPassword(true)}
              sx={{ display: 'block', textAlign: 'center', mt: 1 }}
            >
              Forgot Password?
            </Link>
            <Divider sx={{ my: 2 }}>OR</Divider>
            <Box display="flex" justifyContent="center">
              <GoogleButton
                onClick={handleGoogleSignIn}
                type="light"
              />
            </Box>
          </Paper>
        </Box>
      )}

      {!currentUser && showForgotPassword && (
        <ForgotPassword onBack={() => setShowForgotPassword(false)} />
      )}

      {currentUser && (
        <>
          {canAddMoreJobs() ? (
            <Box mb={4}>
              <JobForm
                newJob={newJob}
                setNewJob={setNewJob}
                handleAddJob={handleAddJob}
                errors={errors}
                subscriptionLevel={subscriptionLevel}
                currentJobs={jobs.length}
              />
            </Box>
          ) : null}

          <Box mb={2}>
            <Typography variant="h5" component="h2" gutterBottom>
              Your Monitoring Jobs
            </Typography>
          </Box>
          {jobs.length === 0 ? (
            <Typography>You don't have any monitoring jobs yet.</Typography>
          ) : (
            <JobList
              jobs={jobs}
              setJobs={setJobs}
              toggleJobStatus={toggleJobStatus}
              deleteJob={deleteJob}
              subscriptionLevel={subscriptionLevel}
              onNotification={handleNotification}
              userId={userId}
              onSubscriptionSuccess={handleSubscriptionSuccess}
            />
          )}

          <Box mb={2} textAlign="right">
            <Button variant="outlined" color="secondary" onClick={handleSignOut}>
              Sign Out
            </Button>
          </Box>
        </>
      )}

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseNotification} severity={notification.severity} sx={{ width: '100%' }}>
          {notification.message}
        </Alert>
      </Snackbar>

      {/* Footer updated with Feature Roadmap link */}
      <Box component="footer" sx={{ mt: 4, py: 3, borderTop: 1, borderColor: 'divider' }}>
        <Typography variant="body2" color="text.secondary" align="center">
          © {new Date().getFullYear()} WebMonitor.fyi. All rights reserved.
          {' | '}
          <Link href="/terms-and-conditions.html" target="_blank" rel="noopener noreferrer">
            Terms and Conditions
          </Link>
          {' | '}
          <Link href="/privacy-policy.html" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </Link>
          {' | '}
          <Link href="/feature-roadmap.html" target="_blank" rel="noopener noreferrer">
            Feature Roadmap
          </Link>
        </Typography>
      </Box>
    </Container>
  );
}

// Wrap the App component with AuthProvider
function AppWithAuth() {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
}

export default AppWithAuth;