import { useEffect, useRef } from 'react';

export default function useWebSocket(wsUrl, jobs, setJobs) {
  const wsRef = useRef(null);
  const reconnectTimeoutRef = useRef(null);

  useEffect(() => {
    let reconnectAttempts = 0;

    function connect() {
      const ws = new WebSocket(wsUrl);
      wsRef.current = ws;

      ws.onopen = () => {
        console.log('WebSocket connected');
        reconnectAttempts = 0; // Reset attempts on successful connection
      };

      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.type === 'jobUpdate') {
          setJobs((prevJobs) =>
            prevJobs.map((job) => (job.id === data.job.id ? data.job : job))
          );
        }
        // Handle other message types if necessary
      };

      ws.onclose = (event) => {
        console.log('WebSocket disconnected');
        if (!event.wasClean) {
          reconnect();
        }
      };

      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
        ws.close();
      };
    }

    function reconnect() {
      if (reconnectAttempts >= 5) {
        console.log('Max reconnection attempts reached');
        return;
      }

      const timeout = Math.pow(2, reconnectAttempts) * 1000; // Exponential backoff
      console.log(`Attempting to reconnect in ${timeout}ms`);

      reconnectTimeoutRef.current = setTimeout(() => {
        reconnectAttempts++;
        connect();
      }, timeout);
    }

    connect();

    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
    };
  }, [wsUrl, setJobs]);
}